import React, { Component } from 'react';
import { observable, reaction, computed } from 'mobx';
import { observer } from 'mobx-react';
import { Story, Node } from './Story.js';
import './CombinePanel.css';

function sentence(node) {
    let text = node.text.trim();

    let char = text.substr(-1);
    if (char !== "." && char !== "!" && char !== "?")
        text += ".";

    text += " ";

    return text;
}

function randomPath(root) {
    let path = [root.id];
    let children = [...root.children];

    while (children.length > 0) {
        let next = children[Math.floor(Math.random() * children.length)];
        path.push(next.id);
        children = next.children;
    }

    return path;
}

function randomNodes(root) {
    let path = [root.id];
    let children = [...root.children];

    while (children.length > 0) {
        let next = children.pop();
        children = children.concat(next.children)

        // 25% chance of including any node.
        if (Math.random() < 0.25) {
            path.push(next.id)
        }
    }

    return path;
}

@observer
class CombinePanel extends Component {
    componentDidMount() {
        reaction(() => this.props.editor.mode, () => this.refresh())
    }

    @computed get paragraphs() {
        let paragraphs = [];

        if (this.props.editor.mode.indexOf("combine") === 0) {
            let all = this.props.editor.mode === "combine-all";
            let layer = [...this.props.story.root.children];
            let nextLayer = [];

            while (layer.length > 0) {
                let text = "";
                let counter = 0;

                for (let i = 0; i < layer.length; i++) {
                    let node = layer[i];

                    if ((all || this.props.editor.selection.has(node.id)) && node.text) {
                        text += sentence(node);

                        counter++;
                        if (counter % 9 == 0) {
                            paragraphs.push(text);
                            text = "";
                        }
                    }

                    nextLayer = nextLayer.concat(node.children)
                }

                if (text) {
                    paragraphs.push(text);
                }

                layer = nextLayer;
                nextLayer = [];
            }
        }

        return paragraphs;
    }

    refresh() {
        switch(this.props.editor.mode) {
            case "combine-all":
                this.props.editor.selection = new Set();
                break;
            case "combine-selection":
                this.props.editor.selection = new Set([this.props.story.root.id]);
                break;
            case "combine-random-path":
                this.props.editor.selection = new Set(randomPath(this.props.story.root));
                break;
            case "combine-random-nodes":
                this.props.editor.selection = new Set(randomNodes(this.props.story.root));
                break;
            default:
                break;
        }
    }

    download() {
        let text = (this.props.story.root.text + "\n\n" + this.paragraphs.join("\n\n")).trim()

        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', 'my-story.txt');

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }

    render() {
        let text = "";
        let editor = this.props.editor;
        let isOpen = editor.mode.indexOf("combine") === 0;

        return (
            <div className={"panel combine-panel " + (isOpen ? "open" : "closed")}>
                <div className="control-strip">
                    <button class="left" onClick={() => editor.mode = "edit"}>Cancel</button>
                    <div className="combine">
                        <header>Mode</header>
                        <button className={editor.mode === "combine-all" ? "active" : ""}
                                onClick={() => editor.mode = "combine-all" }>All</button>
                        <button className={editor.mode === "combine-random-path" ? "active" : ""}
                                onClick={() => { editor.mode = "combine-random-path"; this.refresh() } }>Random Path</button>
                        <button className={editor.mode === "combine-selection" ? "active" : ""}
                                onClick={() => { editor.mode = "combine-selection"; this.refresh() } }>Selected Path</button>
                        <button className={editor.mode === "combine-random-nodes" ? "active" : ""}
                                onClick={() => { editor.mode = "combine-random-nodes"; this.refresh() } }>Random Nodes</button>
                    </div>
                </div>

                <div className="content">

                    <div className="explanation">
                        <button onClick={ this.download.bind(this) }>Download</button>
                        <p>
                            Your sentences have been combined below.
                            Click "download" to get a text file, or
                            use the mode buttons in the lower left to change how
                            the sentences are arranged.
                        </p>
                        { editor.mode == "combine-selection" && (
                            <p>Click sentences on the right side of the screen to add them to the selection.</p>
                        ) || ""
                        }
                    </div>

                    <p className="root-sentence">
                        {this.props.story.root.text}
                    </p>

                    { this.paragraphs.map( p => (
                        <p className="story">{ p.trim() }</p>
                    ))}
                </div>

            </div>
        )
    }
}

export default CombinePanel;