import React, { Component, useEffect } from 'react';
import { observer } from 'mobx-react';
import TextareaAutosize from 'react-autosize-textarea';
import { ScrollSyncPane } from 'react-scroll-sync';
import { Node } from './Story.js'
import './LayerView.css';

@observer
class LayerView extends Component {
    onClick(e) {
        if (this.props.editor.mode !== "combine-selection") {
            this.props.editor.mode = "edit";
        }
    }

    render() {
        let style = { backgroundColor: `hsl(${ (this.props.index * 80) % 360}, 8%, 14%)` };
        let placeholder = this.props.index === 0 ? "Start your story, then click +" : "continue the story...";

        return (
            <ScrollSyncPane>
                <div className={`layer-scroll`} style={style} onClick={this.onClick.bind(this)}>
                <div className="layer-view">
                    {
                        this.props.nodeGroups.map(({ parent, children }) => (
                            <ul>
                                {children.map(n => (
                                    <li>
                                        <NodeView node={n}
                                                  parent={parent}
                                                  editor={this.props.editor}
                                                  selectedPath={this.props.selectedPath}
                                                  placeholder={placeholder} />
                                    </li>
                                ))}
                            </ul>
                        ))
                    }
                </div>
            </div>
            </ScrollSyncPane>
        )
    }
}

@observer
class NodeView extends Component {
    onFocus() {
        this.props.editor.selection = new Set([this.props.node.id])
    }

    onBlur() {
        this.props.editor.selection = new Set()
    }

    keyDown(e) {
        if (e.keyCode === 13) {
            e.preventDefault()
        }
    }

    spawnChildren() {
        for (let i = 0; i < 3; i++) {
            this.props.node.children.push(new Node())
        }
    }

    selectChildren() {
        this.props.editor.selection = new Set([this.props.node.children[0].id])
    }

    buttonClick(e) {
        e.preventDefault();

        if (!this.props.node.children.length) {
            this.spawnChildren();
        }

        this.selectChildren();
    }

    textareaClick(e) {
        if (this.props.editor.mode === "combine-selection") {
            e.preventDefault();
            e.stopPropagation();

            if (this.props.editor.selection.has(this.props.node.id)) {
                this.props.editor.selection.delete(this.props.node.id);
            } else {
                this.props.editor.selection.add(this.props.node.id);
            }
        }

    }
    componentDidMount() {
        if (this.props.editor.selection.values().next().value === this.props.node.id)
            this.textarea.focus()
    }

    componentDidUpdate() {
        if (this.props.editor.mode === "edit" &&
            this.props.editor.selection.values().next().value === this.props.node.id)
            this.textarea.focus()
    }

    render() {
        let className = "";

        if (this.props.editor.selection.has(this.props.node.id))
            className += "selected "

        if (this.props.editor.selection.has(this.props.parent))
            className += "selected-parent "

        if (this.props.selectedPath.has(this.props.node.id))
            className += "selected-path "

        return (
            <div className="node" onMouseDownCapture={this.textareaClick.bind(this)}>
                <TextareaAutosize async={true}
                    className={ className }
                    value={this.props.node.text}
                    innerRef={ref => { this.textarea = ref } }
                    onFocus={ this.onFocus.bind(this) }
                    onBlur={ this.onBlur.bind(this) }
                    onChange={e => this.props.node.text = e.target.value}
                    onKeyDown={this.keyDown.bind(this) }
                    placeholder={this.props.placeholder} />

                {this.props.node.text && (this.props.node.children.length ?
                    <a href="#" onClick={this.buttonClick.bind(this)}>></a> :
                    <a href="#" className="new" onClick={this.buttonClick.bind(this)}>+</a>
                ) || ""}
            </div>
        )
    }
}

export default LayerView;
