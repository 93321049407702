import { observable } from 'mobx';

class Node {
    id = `n_${generateId()}`
    @observable text = "";
    @observable children = [];

    constructor(text, children) {
        if (text){
            this.text = text;
        }
        if (children) {
            this.children = children;
        }
    }
}

class Story {
    id = `s_${generateId()}`;
    @observable root = new Node();
    modified = new Date();
    created = new Date();

    asListItem() {
        return {
            id: this.id,
            text: this.root.text || "Untitled",
            modified: this.modified,
            created: this.created
        }
    }
}

function dec2hex(dec) {
    return ('0' + dec.toString(16)).substr(-2)
}

// generateId :: Integer -> String
function generateId(len) {
    var arr = new Uint8Array((len || 32) / 2)
    window.crypto.getRandomValues(arr)
    return Array.from(arr, dec2hex).join('')
}

export { Node, Story }