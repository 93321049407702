import React, { Component } from 'react';
import { observable, computed, reaction, autorun, action } from 'mobx';
import { observer } from 'mobx-react';
import { ScrollSync } from 'react-scroll-sync';
import { Story, Node } from './Story.js'
import LayerView from './LayerView.js';
import OpenPanel, { loadStory } from './OpenPanel.js';
import CombinePanel from './CombinePanel.js';
import './App.css';

const Modes = {
  Edit: "edit",
  Open: "open",
  CombineAll: "combine-all",
  CombineRandomPath: "combine-random-path",
  CombineRandomNodes: "combine-random-nodes",
  CombineSelection: "combine-selection"
}

@observer
class App extends Component {
  @observable stories;
  @observable _story;
  @observable editor = {
    mode: Modes.Edit,
    selection: new Set()
  }

  constructor() {
    super()


    this.stories = observable(JSON.parse(localStorage.getItem("stories") || "[]"));

    if (this.stories.length) {
      this.story = loadStory(this.stories[0].id);
    } else {
      this.story = new Story();

      /*
      this.story.root.text = "The dog bit the man";
      this.story.root.children = [
        new Node("The man's skin  was rough with scars", [
          new Node("Scars are tissue, which doesn't sound tough"),
          new Node("Which contrasted his pale white coloring"),
          new Node("His face was like sandpaper")
        ]),
        new Node("The dog's jaw was strong", [
          new Node("Second item in the array starts here"),
          new Node("Which contrasted his pale white coloring"),
          new Node("His face was like sandpaper")
        ]),
        new Node("The dog's teeth were dull"),
      ]
      */

    }

    this.storiesSaveHandler = autorun(
      () => this.saveStories()
    )

    this.storySaveHandler = autorun(
      () => this.saveStory()
    )
  }

  // we only need to
  set story(story) {
    let index = this.stories.map(s => s.id).indexOf(story.id)
    if (index !== -1)
      this.stories.splice(index, 1)

    this.stories.unshift(story.asListItem());
    window.stories = this.stories;
    window.story = this._story = story;
  }

  @computed
  get story() {
    return this._story
  }

  saveStories() {
    this.stories[0].text = this.story.root.text;
    localStorage.setItem("stories", JSON.stringify(this.stories));
  }

  saveStory() {
    localStorage.setItem(this.story.id, JSON.stringify(this.story));
  }

  @computed get layers() {
    let layers = [];
    let pendingLayers = [{ parent: null, children: [this.story.root] }];

    while (pendingLayers.length > 0) {
      let newLayer = pendingLayers;
      pendingLayers = [];

      for (let { parent, children } of newLayer) {
        for (let node of children) {
          if (node.children.length) {
            pendingLayers = pendingLayers.concat({ parent: node.id, children: node.children });
          }
        }
      }

      if (newLayer.length) {
        layers.push(newLayer);
      }
    }

    return layers;
  }

  render() {
    let combine = this.editor.mode.indexOf("combine") === 0;

    let selected, selectedPath = new Set();
    if (this.editor.selection.size == 1) {
      selected = this.editor.selection.values().next().value;
      selectedPath.add(selected);

      let layer = this.layers.length;
      while (--layer >= 0) {
        for (let nodeGroup of this.layers[layer]) {
          for (let node of nodeGroup.children) {
            if (selectedPath.has(node.id)) {
              selectedPath.add(nodeGroup.parent);
              break;
            }
          }
        }
      }
    }

    return (
      <div className={`App mode-${this.editor.mode}`}>
        <div className="control-strip">
          <button className="new" onClick={() => this.story = new Story() }>New</button>
          <button className="open" onClick={() => this.editor.mode = Modes.Open }>Open</button>
          <div className="combine">
            <header>Combine</header>
            <button onClick={() => this.editor.mode = Modes.CombineAll }>All</button>
            <button onClick={() => this.editor.mode = Modes.CombineRandomPath }>Random Path</button>
            <button onClick={() => this.editor.mode = Modes.CombineSelection }>Selected Path</button>
            <button onClick={() => this.editor.mode = Modes.CombineRandomNodes }>Random Nodes</button>
          </div>
        </div>

        <ScrollSync>
        <div className={`LayerGroups ${selected ? 'has-selection ' : ' '} ${combine ? 'combine-mode' : ''}`}>
          {this.layers.map((layer, index) => (
            <LayerView index={index}
              editor={this.editor}
              nodeGroups={layer}
              onResize={this.onResize}
              selectedPath={selectedPath} />
          ))}
        </div>
        </ScrollSync>

        <OpenPanel stories={this.stories}
          setStory={ (s) => this.story = s }
          editor={this.editor}
        />

        <CombinePanel story={this.story}
          editor={this.editor}
        />

      </div>
    );
  }}

export default App;
