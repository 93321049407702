import React, { Component } from 'react';
import { observable, reaction } from 'mobx';
import { observer } from 'mobx-react';
import moment from 'moment';
import { Story, Node } from './Story.js';
import './Panel.css';

function loadStory(id) {
  let json = localStorage.getItem(id);
  if (!json)
    throw new Error("No such story!");

  // FIXME: hack.
  return JSON.parse(json, (key, value) => {
    if (/n_/.exec(value.id)) {
      let node = new Node(value.text, value.children);
      node.id = value.id
      return node;
    }

    if (/s_/.exec(value.id)) {
      let story = new Story();
      story.id = value.id;
      story.modified = new Date();
      story.created = value.created;
      story.root = value.root;
      return story;
    }

    return value;
  })
}

@observer
class OpenPanel extends Component {

  render() {
    let isOpen = this.props.editor.mode === "open";
    let close = () => this.props.editor.mode = "edit";

    return (
      <div className={"open-panel panel " + (isOpen ? "open" : "closed")}>
        <div className="control-strip">
          <button onClick={() => { this.props.setStory(new Story()); close(); }}>New</button>
          <button onClick={close}>Cancel</button>
        </div>

        <div className="content">
          <h2>Open an existing Project</h2>

          <ul>
              { this.props.stories.map((p, i) =>
                  <li>
                  <a onClick={() => { this.props.setStory(loadStory(p.id)); close(); }}>
                        <span className="name">{ p.text || "Untitled"}</span>
                        <span className="modified">{ moment(p.modified).fromNow() }</span>
                    </a>
                  </li>
              )}
          </ul>
        </div>
      </div>
    )
  }
}

export { loadStory };
export default OpenPanel;